import React from "react";
import Footer from "../../footer/footer";
import { useParams } from "react-router-dom";
import homeApi from "../../api/homeApi";
import { useState, useEffect } from "react";
import Sharebutton from "../../sharebutton/Sharebutton";
import APINAME from "../../api/apiName";

export default function Postdetailpage() {
  let { id } = useParams();

  //----getdata
  const [formData, setFormData] = useState({
    post_id: "",
    post_date: "",
    main_image_id: "",
    post_titel: "",
    post_content: "",
    update_date: "",
    base64_content: "",
  });
  const [data, setData] = useState([]);

  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(window.location.href);

    //Fetch user data based on the ID from the URL params
    homeApi.get(`news/` + id)
      .then((response) => {
        setFormData({
          ...formData,
          post_id: response.data[0].post_id,
          post_date: response.data[0].post_date,
          main_image_id: response.data[0].main_image_id,
          post_titel: response.data[0].post_titel,
          post_content: response.data[0].post_content,
          update_date: response.data[0].update_datenew,
          base64_content: APINAME+ response.data[0].post_image_name,
        });
      })
      .catch((error) => {
        console.error("Error fetching user data", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    fetch("http://localhost:3001/news-imges-detail/" + id)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));

      
  }, []);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileData, setFileData] = useState([]);

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileItem = {
          name: file.name,
          type: file.type,
          base64: reader.result,
        };
        setFileData((prev) => [...prev, fileItem]);
      };
    });

    setSelectedFiles([...selectedFiles, ...files]);
  };
  const [show, setShow] = useState(false);

  const handleRemove = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);

    const updatedFileData = [...fileData];
    updatedFileData.splice(index, 1);
    setFileData(updatedFileData);
  };

  const handleUpload = () => {
    // Implement your upload logic here
    console.log("Uploading files:", fileData);
  };
  const [selectedImage, setSelectedImage] = useState("");
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShow(true);
  };
  const closeModal = () => {
    setShow(false);
  };

  const renderData = () => {
    if (data.length === 0) {
      return <p></p>; // Or any other loading indicator
    }

    const rows = [];
    for (let i = 0; i < data.length; i += 3) {
      const chunk = data.slice(i, i + 3);
      const chunkMarkup = (
        <div className={`carousel-item ${i === 0 ? "active" : ""}`} key={i}>
          <div className="row">
            {chunk.map((item, idx) => (
              <div
                className="col-md-4 mb-3"
                key={idx}
                onClick={() => handleImageClick(APINAME+ item.post_image_name)}
              >
                <div className="card">
                  <img
                    className="img-fluid"
                    alt="100%x280"
                    src={APINAME+item.post_image_name}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      );
      rows.push(chunkMarkup);
    }

    return (
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">{rows}</div>

        {show && (
          <div
            className="modal "
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-fullscreen text-center"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    onClick={closeModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="">
                    <img className="i" alt="100%x280" src={selectedImage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
 
  return (
    <>

      <br />
      <br />
      <br />
      <div></div>
      <div className="main-div-img">
        <div className="container ">
          <div className="col-lg">
            <article>
              <header className="mb-4">
                <div className="row">
                  <div className="col-sm-8">
                    <h1 className="fw-bolder mb-1">{formData.post_titel}</h1>
                  </div>
                  <div className="col-sm-4 d-flex justify-content-end">
                    <Sharebutton currentUrl={url} />
                  </div>
                </div>

                <div className="text-muted fst-italic mb-2">
                  <div className="col">Posted on {new Date(formData.post_date).toLocaleString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        timeZone: "Asia/Bangkok",
                      })}</div>
                </div>
                {/* <a
                  className="badge bg-secondary text-decoration-none link-light"
                  href="#!"
                >
                  ผลงาน
                </a> */}
                {/* <a
                  className="badge bg-secondary text-decoration-none link-light"
                  href="#!"
                >
                  Freebies
                </a> */}
              </header>
              <figure className="mb-4 d-flex justify-content-center align-items-center">
              <img
  className="img-fluid rounded"
  src={formData.base64_content}
  alt="..."
  style={{
    width: "100%",  // Ensure the image takes 100% of its parent's width
    height: "auto", // Allow the height to adjust proportionally
    maxWidth: "900px", // Set a maximum width if needed
  }}
/>
              </figure>
              <section className="mb-5">
                <div className="preview-container">
                  {/* <h5>Real-Time Preview</h5> */}
                  <div
                    dangerouslySetInnerHTML={{ __html: formData.post_content }}
                  />
                </div>
              </section>
              <section class="pt-5 pb-5">
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                      {/* <h3 class="mb-3">Carousel cards title </h3> */}
                    </div>
                    <div class="col-6 text-right">
                      <a
                        class="btn btn-primary mb-3 mr-1"
                        href="#carouselExampleIndicators2"
                        role="button"
                        data-slide="prev"
                      >
                        <i class="fa fa-arrow-left"></i>
                      </a>
                      <a
                        class="btn btn-primary mb-3 "
                        href="#carouselExampleIndicators2"
                        role="button"
                        data-slide="next"
                      >
                        <i class="fa fa-arrow-right"></i>
                      </a>
                    </div>
                    <div class="col-12">
                    <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
                          <div class="carousel-inner">{renderData()}</div>
                        </div>
                    </div>
                  </div>
                </div>
              </section>
            </article>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
