import React from 'react';
import "./Sharebutton.css";

const Sharebutton = ({currentUrl,img,text}) => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;
    const twitterShareUrl = `https://twitter.com/share?url=${currentUrl}`;
  return (
    <>


   <div class="wrapper">
  <a href={facebookShareUrl} target="_blank" rel="noopener noreferrer" class="icon facebook">
    <div class="tooltip">Facebook</div>
    <span><i class="fab fa-facebook-f"></i></span>
  </a>
  <a href={twitterShareUrl} class="icon twitter">
    <div class="tooltip">Twitter</div>
    <span><i class="fab fa-twitter"></i></span>
  </a>
  {/* <a href="#" class="icon instagram">
    <div class="tooltip">Instagram</div>
    <span><i class="fab fa-instagram"></i></span>
  </a>
  <a href="#" class="icon github">
    <div class="tooltip">Github</div>
    <span><i class="fab fa-github"></i></span>
  </a>
  <a href="#" class="icon youtube">
    <div class="tooltip">Youtube</div>
    <span><i class="fab fa-youtube"></i></span>
  </a> */}
</div>


    </>
  );
}

export default  Sharebutton ;
