import React from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import NewHomeType from "../../img/ch/new-home-type.jpg";
import TrendHomeType from "../../img/ch/new-home-trending.jpg";
import homeApi from "../../api/homeApi";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";
import second_1 from "../../img/ch/template1.png";
import second_2 from "../../img/ch/template2.png";
import second_3 from "../../img/ch/template3.png";
import second_o01 from "../../img/375581429_1034693981287647_6153707239934361640_n.jpg";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min"; // Import Bootstrap JavaScript bundle
import "./homepage.css";
import Footer from "../../footer/footer";

import { useState, useEffect } from "react";
import APINAME from "../../api/apiName";
import UploadFile from "../../uploadfile/uploadfile";
import CurrentNAME from "../../api/currentName";

//import logo from '../../img/LINE_ALBUM_โลโก้_230902_1.jpg'

export default function Homepage() {
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [show, setShow] = useState(false);

  const storeRole = sessionStorage.getItem("Role");
  const [UserRole, setUserRole] = useState();

  useEffect(() => {

    setUserRole(storeRole);
    homeApi
      .get(`news-least`)
      .then((response) => {
        setCarouselData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

      homeApi
      .get(`banner`)
      .then((response) => {
        setCarouselImage(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const fetchdata = () =>{
    homeApi
    .get(`banner`)
    .then((response) => {
      setCarouselImage(response.data);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const redirect = (id) => {
    alert("1");
  };

  const [carouselImage, setCarouselImage] = useState([]);

  const renderImages = () => {
    return carouselImage.map((image, index) => (
      <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
         {UserRole === "admin" && (
        <div className="d-flex justify-content-center align-items-center">
          <button class="btn btn-danger" onClick={() => deleteImage(image.banner_id, image.banner_name)}>ลบภาพ</button>
        </div>
         )}
        <img className="banner" src={APINAME+image.banner_name} alt={`Slide ${index + 1}`} />
      </div>
    ));
  };
  const deleteImage = (id,imagename) => {

    homeApi
      .get(`delete-banner/`+id+`/`+imagename)
      .then((response) => {

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

      window.location.href = CurrentNAME;
  };

  const [carouselData, setCarouselData] = useState([]);
  const renderData = () => {
    const rows = [];
    for (let i = 0; i < carouselData.length; i += 3) {
      const chunk = carouselData.slice(i, i + 3);
      const chunkMarkup = (
        <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
          <div className="row">
            {chunk.map((item, index) => (
              <div key={index} className="col-md-4 mb-3">
                <div className="card">
                  {item.type === "2" ? (
                    <a href={`/Postdetailpage/${item.id}`}>
                      <img
                        className="img-fluid"
                        alt="100%x280"
                        src={APINAME+item.imgname}
                      />
                    </a>
                  ) : (
                    <a href={`/Homepindetail/${item.id}`}>
                      <img
                        className="img-fluid"
                        alt="100%x280"
                        src={APINAME+item.imgname}
                      />
                    </a>
                  )}
      {item.type === "2" ? (
                   <div className="card-body">
                   <h4 className="card-title">{item.title}</h4>
                   <div className="contentcard">
                     <div
                       style={{
                         maxHeight: "60px",
                         overflow: "hidden",
                         textOverflow: "ellipsis",
                         whiteSpace: "normal", // or 'nowrap' if you don't want text wrapping
                         textEmphasisColor: "",
                       }}
                       dangerouslySetInnerHTML={{ __html: item.content }}
                     />
                    
                   </div>
                 </div>
                  ) : (
                    <div className="card-body">
                    <h4 className="card-title">{item.title}</h4>
                    <div className="contentcard">
                      <div
                        style={{
                          maxHeight: "60px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal", // or 'nowrap' if you don't want text wrapping
                          textEmphasisColor: "",
                        }}
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />
                     
                    </div>
                  </div>
                  )}
                 
                </div>
              </div>
            ))}
          </div>
        </div>
      );
      rows.push(chunkMarkup);
    }
    return rows;
  };

  const [selectedValuehometype, setSelectedValuehometype] = useState("");
  const [selectedValuefloor, setSelectedValuehomefloor] = useState("");
  const [selectedValuebedroom, setSelectedValuehomebedroom] = useState("");
  const [selectedValuebathroom, setSelectedValuehomebathroom] = useState("");
  const [selectedValueprice, setSelectedValueprice] = useState("");

  const handleAutocompleteChangehometype = (event, value) => {
    setSelectedValuehometype(value);
  };

  const handleAutocompleteChangefloor = (event, value) => {
    setSelectedValuehomefloor(value);
  };

  const handleAutocompleteChangebedroom = (event, value) => {
    setSelectedValuehomebedroom(value);
  };

  const handleAutocompleteChangebathroom = (event, value) => {
    setSelectedValuehomebathroom(value);
  };

  const handleAutocompleteChangeprice = (event, value) => {
    setSelectedValueprice(value);
  };

  const jsonData = {
    selectedValuehometype,
    selectedValuefloor,
    selectedValuebedroom,
    selectedValuebathroom,
    selectedValueprice,
  };
  const [isDivVisible, setDivVisibility] = useState(false);

  const toggleDiv = () => {
    setDivVisibility(!isDivVisible);
  };
  return (
    <>
      <br />
      <br />
      <br />
      <div class="row">
        <div class="col-xl-12">

        </div>
      </div>
      <div className="main-div-img">
        <Container maxWidth="lg">
        {UserRole === "admin" && (
          <UploadFile/>)}
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              {/* <div class="carousel-item active">
                <img class="banner" src={second_o01} alt="First slide" />
              </div> */}

{renderImages()}
            </div>

           
            <a
              class="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>

          <div className="list-menu-homepage">
            <div className="padding-Title-Page">
              <h1 className="Title-Page">แบบบ้าน</h1>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col">{/* <h1>ค้นหาข้อมูล</h1> */}</div>
              <div className="col">
                <button
                  type="button"
                  class="btn btn btn-rounded btn-block custom-button"
                  onClick={toggleDiv}
                >
                  ค้นหาแบบบ้าน
                </button>
              </div>
            </div>
            <br />
            {isDivVisible && (
              <div className="container seachbar">
                <br />
                <div className="row">
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      color="white"
                      id="combo-box-demo"
                      options={hometype}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangehometype}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="แบบบ้าน"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={level}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangefloor}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ชั้น"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={bedroom}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangebedroom}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ห้องนอน"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={bathroom}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangebathroom}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ห้องน้ำ"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={price}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangeprice}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ราคา"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-1 col-sm-3 d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      class="btn btn btn-rounded btn-block custom-button"
                    >
                      {" "}
                      <Link
                        className="seach-link"
                        to="/homepin"
                        state={jsonData}
                      >
                        ค้นหา
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <br />

          <div class="container">
            <div class="row">
              {/* <div class="col-12 col-sm-8 col-md-6 col-lg-4"> */}
              <div class=" mb-4 col-lg-6">
                <div class="card">
                  <img class="card-img" src={NewHomeType} alt="" />
                  <div class="card-img-overlay text-overlay position-absolute top-0 start-0  d-flex   justify-content-center">
                    <div className="flex-column ">
                      <h4 class="card-title text-center">แบบบ้านล่าสุด</h4>
                      <a className="card-link" href="/Homepin/4">
                        <h6 class="card-subtitle mb-2 text-center">
                          ดูข้อมูลเพิ่มเติม{" "}
                          <i
                            class="fa fa-arrow-circle-o-right"
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" col-lg-6">
                <div class="card">
                  <img class="card-img" src={TrendHomeType} alt="" />
                  <div class="card-img-overlay text-overlay position-absolute top-0 start-0  d-flex   justify-content-center">
                    <div className="flex-column ">
                      <h4 class="card-title text-center">แบบบ้านยอดนิยม</h4>
                      <a className="card-link" href="/Homepin/4">
                        <h6 class="card-subtitle mb-2 text-center">
                          ดูข้อมูลเพิ่มเติม{" "}
                          <i
                            class="fa fa-arrow-circle-o-right"
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <br />

            <div class="row">
              <div class="col-12 col-md-4 mb-4 d-flex justify-content-center align-items-center position-relative">
                <img
                  className="type-post-img-second"
                  src={second_1}
                  alt="First slide"
                />
                <div class="text-overlay position-absolute top-0 start-0  d-flex flex-column justify-content-center ">
                  <h3 class="second-txt text-center">แบบบ้านทรัพย์อนันต์</h3>
                  <a className="card-link" href="/Homepin/2">
                    <p class="third-txt text-center">
                      ดูข้อมูลเพิ่มเติม{" "}
                      <i
                        class="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </a>
                </div>
              </div>

              <div class="col-12 col-md-4 mb-4 d-flex justify-content-center align-items-center position-relative">
                <img
                  className="type-post-img-second"
                  src={second_2}
                  alt="First slide"
                />
                <div class="text-overlay position-absolute top-0 start-0  d-flex flex-column justify-content-center ">
                  <h3 class="second-txt text-center">แบบบ้านชยางกูร</h3>
                  <a className="card-link" href="/Homepin/1">
                    <p class="third-txt text-center">
                      ดูข้อมูลเพิ่มเติม{" "}
                      <i
                        class="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </a>
                </div>
              </div>

              <div class="col-12 col-md-4 mb-4 d-flex justify-content-center align-items-center position-relative">
                <img
                  className="type-post-img-second"
                  src={second_3}
                  alt="First slide"
                />
                <div class="text-overlay position-absolute top-0 start-0  d-flex flex-column justify-content-center ">
                  <h3 class="second-txt text-center">แบบบ้านอื่นๆ</h3>
                  <a className="card-link" href="/Homepin/3">
                    <p class="third-txt text-center">
                      ดูข้อมูลเพิ่มเติม{" "}
                      <i
                        class="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="list-menu-homepage">
            <div className="padding-Title-Page">
              <h1 className="Title-Page">อัพเดต</h1>
            </div>
          </div>

          <section class="pt-5 pb-5">
            <div class="container">
              <div class="row">
                <div class="col-6">
                  {/* <h3 class="mb-3">Carousel cards title </h3> */}
                </div>
                <div class="col-6 text-right">
                  <a
                    class="btn btn-primary mb-3 mr-1"
                    href="#carouselExampleIndicators2"
                    role="button"
                    data-slide="prev"
                  >
                    <i class="fa fa-arrow-left"></i>
                  </a>
                  <a
                    class="btn btn-primary mb-3 "
                    href="#carouselExampleIndicators2"
                    role="button"
                    data-slide="next"
                  >
                    <i class="fa fa-arrow-right"></i>
                  </a>
                </div>
                <div class="col-12">
                  <div
                    id="carouselExampleIndicators2"
                    class="carousel slide"
                    data-ride="carousel"
                  >
                    <div class="carousel-inner">{renderData()}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
      </div>
      <div></div>
      <Footer />
    </>
  );
}

const hometype = [
  { hometype_id: "1", label: "ชยางกูร" },
  { hometype_id: "2", label: "ทรัพย์อนัต์" },
  { hometype_id: "3", label: "อื่นๆ" },
];

const level = [
  { level_id: "1", label: "1" },
  { level_id: "2", label: "2" },
  { level_id: "3", label: "3" },
  { level_id: "4", label: "4" },
];

const bedroom = [
  { level_id: "1", label: "1" },
  { level_id: "2", label: "2" },
  { level_id: "3", label: "3" },
  { level_id: "4", label: "4" },
];

const bathroom = [
  { level_id: "1", label: "1" },
  { level_id: "2", label: "2" },
  { level_id: "3", label: "3" },
  { level_id: "4", label: "4" },
];

const price = [
  { pricestart: "500000", priceend: "1000000", label: "0.5 ล้าน - 1 ล้าน" },
  {
    pricestart: "1000000",
    priceend: "1500000",
    label: "1 ล้าน - 1.5 ล้าน",
  },
  {
    pricestart: "1500000",
    priceend: "2000000",
    label: "1.5 ล้าน - 2 ล้าน",
  },
  {
    pricestart: "2000000",
    priceend: "2500000",
    label: "2 ล้าน - 2.5 ล้าน",
  },
];
