import React, { useRef } from "react";
import second_11 from "../../img/ch/11.jpg";
import bedlogo from "../../img/47632-removebg-preview.png";
import "./homepin.css";
import Footer from "../../footer/footer";
import { useState, useEffect } from "react";
import homeApi from "../../api/homeApi";
import Swal from "sweetalert";
import { Modal, Button } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import APINAME from "../../api/apiName";

export default function Homepin() {
  let { id } = useParams();
  const location = useLocation();
  let jsonData = location.state;

  const [UserRole, setUserRole] = useState();

  const storeRole = sessionStorage.getItem("Role");

  const [selectedValuehometype, setSelectedValuehometype] = useState("");
  const [selectedValuefloor, setSelectedValuehomefloor] = useState("");
  const [selectedValuebedroom, setSelectedValuehomebedroom] = useState("");
  const [selectedValuebathroom, setSelectedValuehomebathroom] = useState("");
  const [selectedValueprice, setSelectedValueprice] = useState("");

  const handleAutocompleteChangehometype = (event, value) => {
    setSelectedValuehometype(value);
  };

  const handleAutocompleteChangefloor = (event, value) => {
    setSelectedValuehomefloor(value);
  };

  const handleAutocompleteChangebedroom = (event, value) => {
    setSelectedValuehomebedroom(value);
  };

  const handleAutocompleteChangebathroom = (event, value) => {
    setSelectedValuehomebathroom(value);
  };

  const handleAutocompleteChangeprice = (event, value) => {
    setSelectedValueprice(value);
  };

  //----------
  const [titel, settitel] = useState("");

  const [data, setData] = useState([]);
  //--------------
  const [file, setFile] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [fileType, setfileType] = useState();
  const [fileName, setfileName] = useState();
  const [fileId, setFileId] = useState([]);

  const [homepinid, setHomepinid] = useState("");
  const [homepinname, setHomepinname] = useState([]);
  const [nliviingromm, setnliviingromm] = useState([]);
  const [nkitchenroom, setNkitchenroom] = useState([]);
  const [nbedroom, setnbedroom] = useState([]);
  const [nbathroom, setbathroom] = useState([]);
  const [nparking, setparking] = useState([]);
  const [housefloor, sethousefloor] = useState([]);
  const [mainstyleid, setMainstyleid] = useState(1);
  const [mainstylename, setMainstylename] = useState();
  const [priceragestart, setPriceragestart] = useState([]);
  const [priceDiscount, setPriceDiscount] = useState([]);
  const [ndining, setNdining] = useState([]);

  const [deletebutton, setdeletebutton] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleSelectChange = (event) => {
    setMainstyleid(event.target.value);
  };

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileItem = {
          file_id: 0,
          name: file.name,
          type: file.type,
          base64: reader.result,
        };
        setFileData((prev) => [...prev, fileItem]);
      };
    });

    setSelectedFiles([...selectedFiles, ...files]);
  };

  const [deletedFiles, setDeletedFiles] = useState([]);

  const handleRemove = (index) => {
    const updatedFiles = [...selectedFiles];
    const deletedFile = updatedFiles.splice(index, 1)[0]; // Remove the file from selectedFiles and store the deleted file
    setSelectedFiles(updatedFiles);

    const updatedFileData = [...fileData];
    updatedFileData.splice(index, 1);
    setFileData(updatedFileData);

    setDeletedFiles((prev) => [...prev, deletedFile.pin_image_id]);
  };

  function convertToBase64() {
    // Read File
    var selectedFile = document.getElementById("formFileMultiple").files;

    // Check File is not Empty
    if (selectedFile.length > 0) {
      // Select the very first file from the list
      var fileToLoad = selectedFile[0];

      // Get the file type and name
      var fileType = fileToLoad.type;
      var fileName = fileToLoad.name;

      // FileReader function for reading the file.
      var fileReader = new FileReader();

      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        var base64 = fileLoadedEvent.target.result;
        // You can now use fileType, fileName, and base64 as needed
        setfileType(fileType);
        setfileName(fileName);
        setFile(base64);
      };

      // Read the file as a data URL
      fileReader.readAsDataURL(fileToLoad);
    }
  }

  useEffect(() => {
    // Fetch data from API
    // Example API call
    setUserRole(storeRole);
    if (typeof id !== "undefined") {
      homeApi
        .get(`pin-post/${id}`)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      switch (id) {
        case "1": // Changed from 'id === 1' to '1'
          settitel("แบบบ้านชยางกูร");
          break;
        case "2": // Changed from 'id === "2"' to '2'
          settitel("แบบบ้านทรัพย์อนันต์");
          break;
        case "4": // Changed from 'id === "2"' to '2'
          settitel("แบบบ้านล่าสุด");
          break;
        case "5": // Changed from 'id === "2"' to '2'
          settitel("แบบบ้านยอดนิยม");
          break;
        case "3": // Changed from 'id === "2"' to '2'
          settitel("แบบบ้านอื่นๆ");
          break;
        default:
          // If 'id' doesn't match any case, you can handle it here
          break;
      }
    } else {
      settitel("แบบบ้าน");

      homeApi
        .post("searchpin-post", jsonData)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  const fetchData = () => {
    homeApi
      .get(`pin-post/${id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const SearchData = () => {
    jsonData = {
      selectedValuehometype: selectedValuehometype ? selectedValuehometype : "",
      selectedValuefloor: selectedValuefloor ? selectedValuefloor : "",
      selectedValuebedroom: selectedValuebedroom ? selectedValuebedroom : "",
      selectedValuebathroom: selectedValuebathroom ? selectedValuebathroom : "",
      selectedValueprice: selectedValueprice ? selectedValueprice : "",
    };

    homeApi
      .post("searchpin-post", jsonData)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const clearData = () => {
    // Clear the data for each Autocomplete component
    jsonData = {
      selectedValuehometype: "",
      selectedValuefloor: "",
      selectedValuebedroom: "",
      selectedValuebathroom: "",
      selectedValueprice: "",
    };

    homeApi
      .post("searchpin-post", jsonData)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // Assuming this is within a React component
  // Make sure to replace the existing code with this updated code.

  const renderData = () => {
    if (data.length === 0) {
      return (
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">ไม่พบข้อมูล</span>
          </div>
        </div>
      ); // Or any other loading indicator
    }

    const rows = [];
    for (let i = 0; i < data.length; i += 3) {
      rows.push(
        <div className="row" key={i}>
          {data.slice(i, i + 3).map((item, index) => (
            <div className="col-lg-4 mb-4" key={index}>
              <div className="card">
                <img
                  src={APINAME + item.pin_image_name}
                  alt=""
                  className="card-img-top"
                />
                <div class="card-img-overlay text-overlay position-absolute top-0 start-0  d-flex   justify-content-end">
                 
                  <div className="flex-column ">

                  {item.price_discount !== 0 ?( <h6 class="card-title text-center ">
                      จากปกติ {(item.price_rage_start / 1000000).toFixed(2)}{" "}
                      ล้านบาท
                    </h6>):(
                      <h6 class="card-title text-center ">
                     
                    </h6>
                    )}
                   

                    <h4 class="card-subtitle mb-2 text-center promotion">
                      
                      {(
                        (item.price_discount !== 0
                          ? "ลดเหลือ"
                          : "ราคา"
                      ))}{" "}
                      {(
                        (item.price_discount !== 0
                          ? item.price_discount
                          : item.price_rage_start) / 1000000
                      ).toFixed(2)}{" "}
                      ล้านบาท
                    </h4>
                  </div>
                </div>
{item.main_style_id == "1" &&(  <div className="container titile">
                  <h2 className="custom-title mt-2 d-flex justify-content-between">
                    <span>ฮักสร้างบ้าน</span>
                    <span>{item.home_pin_name}</span> 
                    {/* <span>{item.main_style_id}</span>  */}
                    {/* main_style_id */}
                  </h2>
                </div>)}
                {item.main_style_id == "2" &&(  <div className="container titile-2">
                  <h2 className="custom-title-2 mt-2 d-flex justify-content-between">
                    <span>ฮักสร้างบ้าน</span>
                    <span>{item.home_pin_name}</span> 
                    {/* <span>{item.main_style_id}</span>  */}
                    {/* main_style_id */}
                  </h2>
                </div>)}

                {item.main_style_id == "3" &&(  <div className="container titile-3">
                  <h2 className="custom-title-3 mt-2 d-flex justify-content-between">
                    <span>ฮักสร้างบ้าน</span>
                    <span>{item.home_pin_name}</span> 
                    {/* <span>{item.main_style_id}</span>  */}
                    {/* main_style_id */}
                  </h2>
                </div>)}
              

                <div className="card-body">
                  <div className="row">
                    <h6>
                      {" "}
                      ราคา :
                      {(item.price_discount !== 0
                        ? item.price_discount
                        : item.price_rage_start
                      ).toLocaleString()}{" "}
                      บาท
                    </h6>
                    <span>
                      {/* <i class="fa-solid fa-bed"></i>  */}
                      <img src={bedlogo} alt="" className="bedlogo" />:{" "}
                      {item.number_of_bedroom} ห้องนอน{" "}
                      <i class="fa-solid fa-toilet"></i> :{" "}
                      {item.number_of_bathroom} ห้องน้ำ{" "}
                      <i class="fa fa-cutlery" aria-hidden="true"></i> :{" "}
                      {item.number_of_kitchen} ห้องครัว
                    </span>
                    <p className="card-text">{item.description}</p>
                  </div>
                  <a
                    href={`/Homepindetail/${item.home_pin_id}`}
                    className="btn btn-outline-success btn-sm"
                  >
                    อ่านเพิ่มเติม
                  </a>
                  {UserRole === "admin" && (
                    <Button
                      className="btn btn-outline-danger btn-sm"
                      variant="btn-outline-danger"
                      onClick={() => ViewData(item.home_pin_id)}
                    >
                      แก้ไข
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return rows;
  };
  const fileInputRef = useRef(null);
  const fileInputMutiRef = useRef(null);

  const clearAllData = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }

    if (fileInputMutiRef.current) {
      fileInputMutiRef.current.value = null;
    }

    setFile(null);
    setSelectedFiles([]);
    setFileData([]);
    setfileType(null);
    setfileName(null);
    setFileId([]);
    setHomepinid("");
    setHomepinname([]);
    setnliviingromm([]);
    setNkitchenroom([]);
    setnbedroom([]);
    setbathroom([]);
    setparking([]);
    sethousefloor([]);
    setMainstyleid(1);
    setPriceragestart([]);
    setNdining([]);
    setdeletebutton(false);
    setPriceDiscount([]);
  };

  const validateInput = () => {
    // Your validation logic here
    // For example, checking if specific variables are not null or empty
  
    if (
      homepinid === null 
      ||
      homepinname === null ||
      homepinname.length === 0
       ||
      nliviingromm.length === 0 ||
      nkitchenroom.length === 0 ||
      nbedroom.length === 0 ||
      nbathroom.length === 0 ||
      nparking.length === 0 ||
      housefloor.length === 0 
      ||
      mainstylename === null ||
      priceragestart.length === 0 ||
      priceDiscount.length === 0 ||
      ndining.length === 0
    ) {
      // Invalid input
      return true;
    } else {
      // Valid input
      return false;
    }
  };


  const InserData = async () => {
    if (homepinname === null) {
      alert("null");
    } else if (validateInput()) {
      Swal({
        icon: "error",
        text: "กรุณากรอกข้อมูลให้ครบ",
      });
    } else {
      try {
        const response = await homeApi.post("create-pin-house", {
          homepinid: homepinid,
          homepinname: homepinname,
          ndining: ndining,
          nliviingromm: nliviingromm,
          nkitchenroom: nkitchenroom,
          nbedroom: nbedroom,
          nbathroom: nbathroom,
          nparking: nparking,
          housefloor: housefloor,
          mainstyleid: mainstyleid,
          main_img_id: fileId,
          priceragestart: priceragestart,
          pricediscount: priceDiscount,
          fileName: fileName,
          fileType: fileType,
          file: file,
          fileData: fileData,
          deletedFiles: deletedFiles,
        });

        if (response.data.status) {
          setHomepinid(response.data.homepinid);
          setdeletebutton(true);
          fetchData();

          Swal({
            icon: "success",
            text: response.data.msg,
          });
        }
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("Error inserting data:", error);
        // You might want to throw the error or handle it in a different way depending on your use case
        throw error;
      }
    }
  };

  const DeleteData = async (index) => {
    const response = await homeApi.get(`delete-pin-house/${index}`);

    if (response.data.delete_status) {
      Swal({
        icon: "success",
        text: response.data.msg,
      }).then((result) => {
        setShow(false);
        clearAllData();
        fetchData();
      });

      // var modal = document.getElementById("exampleModal");
      // modal.style.display = "none";
    }
  };

  const handleopen = () => setShow(true);

  const addData = async () => {
    clearAllData();
    handleopen();
  };

  const ViewData = async (index) => {
    handleopen();
    setHomepinid(index);

    const response = await homeApi.get(`get-pin-house/${index}`);

    setHomepinid(response.data.homepinid);
    setHomepinname(response.data.homepinname);
    setFile(response.data.file);

    setfileType(response.data.fileType);
    setfileName(response.data.fileName);
    setFileId(response.data.main_img_id);
    setnliviingromm(response.data.nliviingromm);
    setNkitchenroom(response.data.nkitchenroom);
    setnbedroom(response.data.nbedroom);
    setbathroom(response.data.nbathroom);
    setparking(response.data.nparking);
    sethousefloor(response.data.housefloor);
    setMainstyleid(response.data.mainstyleid);
    setPriceragestart(response.data.priceragestart);
    setPriceDiscount(response.data.price_discount);
    setNdining(response.data.ndining);

    setSelectedFiles(response.data.fileData);

    console.log(response.data.fileData);

    setFileData(
      response.data.fileData.map((file) => ({
        file_id: file.pin_image_id,
        name: file.pin_image_name,
        type: file.pin_image_type,
        base64: file.base64_content,
      }))
    );

    console.log(selectedFiles);

    // const updatedFiles = [...fileData];
    // updatedFiles.splice(index, 1);
    // setSelectedFiles(updatedFiles);

    setdeletebutton(true);
  };
  const [isDivVisible, setDivVisibility] = useState(false);

  const toggleDiv = () => {
    setDivVisibility(!isDivVisible);
  };

  const [filebase, setFileBase] = useState();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    // Assuming you want to display the selected file path
    const filePath = URL.createObjectURL(selectedFile);
    setFile(filePath);

    // Other file-related states
    setfileType(selectedFile.type);
    setfileName(selectedFile.name);
    setFileBase(selectedFile);
  };

  return (
    <>
      <br />
      <br />
      <br />
      <br />

      <section id="gallery ">
        <div className="main-div-img">
          <div className="container ">
            <div className="row">
              <div className="col-8">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a className="card-link" href="/">หน้าหลัก</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      แบบบ้าน
                    </li>
                  </ol>
                </nav>
              </div>
              {UserRole === "admin" && (
                <div className="col-4  text-right">
                  <Button className="addbutton" variant="white"  onClick={() => addData()}>
                    เพิ่มโพส
                  </Button>
                </div>
              )}
            </div>
            <div className="list-menu-homepage">
              <div className="padding-Title-Page">
                <h1 className="Title-Page">{titel}</h1>
              </div>
            </div>
            <div className="row">
              <div className="col">{/* <h1>ค้นหาข้อมูล</h1> */}</div>
              <div className="col">
                <button
                  type="button"
                  class="btn btn btn-rounded btn-block custom-button"
                  onClick={toggleDiv}
                >
                  ค้นหาแบบบ้าน
                </button>
              </div>
            </div>
            <br />
            {isDivVisible && (
              <div className="container seachbar ">
                <br />
                <div className="row">
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      color="white"
                      id="combo-box-demo"
                      options={hometype}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangehometype}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="แบบบ้าน"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={level}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangefloor}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ชั้น"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={bedroom}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangebedroom}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ห้องนอน"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={bathroom}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangebathroom}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ห้องน้ำ"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={price}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangeprice}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ราคา"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-2 col-sm-3 d-flex justify-content-right align-items-center">
                    <div className="col">
                      <button
                        type="button"
                        class="btn  btn-block custom-button"
                        onClick={SearchData}
                      >
                        {" "}
                        ค้นหา
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        class="btn  btn-block custom-button"
                        onClick={clearData}
                      >
                        {" "}
                        ล้าง
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {renderData()}
          </div>
          <Footer />
        </div>
      </section>

      {/* <Button variant="primary" onClick={() => setShow(true)}>
        Open Modal
      </Button> */}

      <Modal show={show} onHide={handleClose} dialogClassName="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div class="row">
              <div class="form-group col-xl-12">
                <label for="inputEmail4">ชื่อแบบบ้าน</label>
                <input
                  type="text"
                  class="form-control input-required"
                  onChange={(event) => {
                    setHomepinname(event.target.value);
                  }}
                  value={homepinname}
                  required="true"
                />

                <div class="form-group col-12">
                  <label>Select an option:</label>
                  <select
                    class="form-select"
                    value={mainstyleid}
                    onChange={handleSelectChange}
                  >
                    <option value="1">แบบบ้านชยางกูร</option>
                    <option value="2">แบบบ้านทรัพย์อนัน</option>
                    <option value="3">แบบบ้านอื่นๆ</option>
                  </select>
                </div>

                <label for="inputEmail4">ภาพหลัก</label>

                <div>
                  <input
                    ref={fileInputRef}
                    className="form-control"
                    type="file"
                    accept="image/*"
                    id="formFileMultiple"
                    onChange={convertToBase64}
                    multiple
                  />
                </div>
              </div>
            </div>

            <div className="container">
              <br />
              <table class="table">
                <tbody>
                  <tr>
                    <th>ช่วงราคา</th>
                    <td>
                      {" "}
                      <div class="row">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="ราคาเริ่มต้น"
                            onChange={(event) => {
                              setPriceragestart(event.target.value);
                            }}
                            value={priceragestart}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>ราคาลด</th>
                    <td>
                      {" "}
                      <div class="row">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="ราคาลด"
                            onChange={(event) => {
                              setPriceDiscount(event.target.value);
                            }}
                            value={priceDiscount}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>ห้องนอน</th>
                    <td>
                      {" "}
                      <input
                        type="text"
                        class="form-control"
                        onChange={(event) => {
                          setnbedroom(event.target.value);
                        }}
                        value={nbedroom}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>ห้องน้ำ</th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        onChange={(event) => {
                          setbathroom(event.target.value);
                        }}
                        value={nbathroom}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>ห้องรับแขก</th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        onChange={(event) => {
                          setnliviingromm(event.target.value);
                        }}
                        value={nliviingromm}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>ห้องทานอาหาร</th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        onChange={(event) => {
                          setNkitchenroom(event.target.value);
                        }}
                        value={nkitchenroom}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>ห้องครัว</th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        onChange={(event) => {
                          setNdining(event.target.value);
                        }}
                        value={ndining}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>ลานจอดรถ</th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        onChange={(event) => {
                          setparking(event.target.value);
                        }}
                        value={nparking}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>จำนวนชั้น</th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        onChange={(event) => {
                          sethousefloor(event.target.value);
                        }}
                        value={housefloor}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <input
              className="form-control"
              ref={fileInputMutiRef}
              type="file"
              multiple
              onChange={handleFileInputChange}
              value={fileData.count}
            />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>File Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {fileData.map((file, index) => (
                  <tr key={index}>
                    <td>{file.name}</td>
                    <td>{file.type} bytes</td>
                    <td>
                      <button onClick={() => handleRemove(index)}>
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </form>
        </Modal.Body>
        <Modal.Footer>
        
          <Button variant="white" className="addbutton"  onClick={InserData}>
            บันทึก
          </Button>
          {deletebutton && (
            <Button variant="white" className="addbutton"  onClick={() => DeleteData(homepinid)}>
              ลบข้อมูล
            </Button>
          )}
            <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const hometype = [
  { hometype_id: "1", label: "ชยางกูร" },
  { hometype_id: "2", label: "ทรัพย์อนัต์" },
  { hometype_id: "3", label: "อื่นๆ" },
];

const level = [
  { level_id: "1", label: "1" },
  { level_id: "2", label: "2" },
  { level_id: "3", label: "3" },
  { level_id: "4", label: "4" },
];

const bedroom = [
  { level_id: "1", label: "1" },
  { level_id: "2", label: "2" },
  { level_id: "3", label: "3" },
  { level_id: "4", label: "4" },
  { level_id: "5", label: "5" },
];

const bathroom = [
  { level_id: "1", label: "1" },
  { level_id: "2", label: "2" },
  { level_id: "3", label: "3" },
  { level_id: "4", label: "4" },
  { level_id: "5", label: "5" },
];
const price = [
  { pricestart: "500000", priceend: "1000000", label: "0.5 ล้าน - 1 ล้าน" },
  {
    pricestart: "1000000",
    priceend: "1500000",
    label: "1 ล้าน - 1.5 ล้าน",
  },
  {
    pricestart: "1500000",
    priceend: "2000000",
    label: "1.5 ล้าน - 2 ล้าน",
  },
  {
    pricestart: "2000000",
    priceend: "2500000",
    label: "2 ล้าน - 2.5 ล้าน",
  },
];
