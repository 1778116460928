
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Import Bootstrap JavaScript bundle
import 'font-awesome/css/font-awesome.min.css';
import './footer.css'


export default function Footer() {
  return (
    <>


  <footer class="text-center text-lg-start footer-home" > 

  <div class="container">
  <div class="row">
    <div class="col">
    <div class="container justify-content-center py-3 text-footer">
      <h4>ฮักสร้างบ้าน</h4>
 
      <h5>รับสร้างบ้าน ต่อเติม ออกแบบ เขียนแบบ ประเมิณราคา</h5>
      <h5>0902392396</h5>
      </div>
      </div>
    <div class="col">
    <div class="container d-flex justify-content-center py-5">
      <a href='https://web.facebook.com/Hug.Sang.Ban' type="button" class="btn btn-primary btn-lg btn-floating mx-2 bt-footer" >
      <FontAwesomeIcon icon={faFacebook} size="lg" />
      </a>
      <button type="button" class="btn btn-primary btn-lg btn-floating mx-2 bt-footer" >
      <FontAwesomeIcon icon={faInstagram} size="lg" />
      </button>
      <button type="button" class="btn btn-primary btn-lg btn-floating mx-2  bt-footer" >
      <FontAwesomeIcon icon={faGoogle} size="lg" />
      </button>
      <button type="button" class="btn btn-primary btn-lg btn-floating mx-2 bt-footer" >
      <FontAwesomeIcon icon={faTwitter} size="lg" />
      </button>
    </div>
    </div>
  </div>
</div>
    

    <div class="text-center text-white p-3 button-lower" >
      © 2020 Copyright:
      <a class="text-white" href="https://mdbootstrap.com/">ฮักสร้างบ้าน.com</a>
    </div>
  
  </footer>
  



    </>
  );
}
