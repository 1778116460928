import logo from '../../src/img/logo2.png'
import Container from "@mui/material/Container";
import { useState,useEffect } from 'react';
import CurrentNAME from '../api/currentName';
export default function Topbar3() {
  const storeRole = sessionStorage.getItem("Role");
  const [UserRole, setUserRole] = useState();

  useEffect(() => {

    setUserRole(storeRole);

    }, []);

    const handleClearSessionStorage = () => {
      // Clear all items from sessionStorage
      sessionStorage.clear();
      // Optionally, you can also remove specific items using sessionStorage.removeItem('key');
      window.location.href = CurrentNAME;
    };


  return (
    <>

<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
<Container maxWidth="lg">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
  <img  className='logo-web-site' src={logo}  alt=''/>
  <ul className="navbar-nav">
      <li className="nav-item active">
        <a className="nav-link" href="/">หน้าหลัก <span className="sr-only">(current)</span></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/Homepin/4">แบบบ้าน</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/Postpage/1">ผลงาน</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/Postpage/2">ข่าวสาร</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/contractpage">ติดต่อ</a>
      </li>
     
    </ul>
    {UserRole === "admin" && (
 <div>
      {/* Your component content goes here */}
      <button class="btn btn-warning"  onClick={handleClearSessionStorage}>ออกจากระบบ</button>
    </div>
    )}
  </div>
    
  </Container>
</nav>



    </>
  )
}
