import React from "react";
import "./contractpage.css";
import MAP from "../../img/MAP.jpg";
import Footer from "../../footer/footer";
import homeApi from "../../api/homeApi";
import { useState } from "react";
import swal from "sweetalert";

export default function Contractpage() {
  const [name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const CantactLine = (event, value) => {
    if (name !== "" && (Email !== "" || phonenumber !== "") && subject !== "") {
      try {
        homeApi
          .post("contact-line", {
            name: name,
            email: Email,
            phonenumber: phonenumber,
            subject: subject,
            message: message,
          })
          .then((response) => {
            if (response.data.message === "ok") {
              swal({
                icon: "success",
                text: response.data.message,
              });
            }
          });
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("Error inserting data:", error);
        // You might want to throw the error or handle it in a different way depending on your use case
        throw error;
      }
    }else{
        swal({
            icon: "warning",
            text: "กรุณากรอกข้อมูลให้ครบ",
          });
    }
  };

  return (
    <>
      <br />
      <br />
      <br />

      <div className="main-div-img">
        <div className="container">
          <br />

          <div className="row text-center">
            <h1>Contacts</h1>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-6">
              <div className="card h-100">
                <div className="card-body">
                  <div className="row">
                    <div className="col-1">
                      <h5>
                        <i class="fa-solid fa-location-dot"></i>
                      </h5>
                    </div>
                    <div className="col contractheader">
                      {" "}
                      <span> ที่อยู่ติดต่อ</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col contract-detail">
                      <h6>
                        {" "}
                      
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <h5>
                        <i class="fa-solid fa-phone"></i>
                      </h5>
                    </div>
                    <div className="col contractheader">
                      {" "}
                      <span> โทรศัพท์</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <h5></h5>
                    </div>
                    <div className="col contract-detail">
                      {" "}
                      <h6> 090-239-2396</h6>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1">
                      <h5>
                        <i class="fa-solid fa-envelope"></i>
                      </h5>
                    </div>
                    <div className="col contractheader">
                      {" "}
                      <span> EMAL</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <h5></h5>
                    </div>
                    <div className="col contract-detail">
                      {" "}
                      <h6> hungsangbaan@hungsangbaan.com</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <h5>
                        <i class="fa-solid fa-map-location-dot"></i>
                      </h5>
                    </div>
                    <div className="col contractheader">
                      {" "}
                      <span> แผนที่ตั้งบริษัท</span>
                    </div>
                  </div>

                  <div className="row maprow">
                    <img className="Map-location" src={MAP} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">ติดต่อสอบถาม</h5>

                  <div class="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ชื่อ"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      value={name}
                    />
                  </div>
                  <div class="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="เบอร์โทรศัพท์"
                      onChange={(event) => {
                        setPhonenumber(event.target.value);
                      }}
                      value={phonenumber}
                    />
                  </div>
                  <div class="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email Adress"
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      value={Email}
                    />
                  </div>

                  <div class="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="หัวข้อเรื่อง"
                      onChange={(event) => {
                        setSubject(event.target.value);
                      }}
                      value={subject}
                    />
                  </div>

                  <div class="mb-3">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="ข้อความ"
                      rows="13"
                      onChange={(event) => {
                        setMessage(event.target.value);
                      }}
                      value={message}
                    ></textarea>
                  </div>

                  <div className="mb-3">
                    <button
                      onClick={CantactLine}
                      href="#"
                      class="btn btn-primary"
                    >
                      ส่งข้อความ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br>
        </div>

        <Footer />
      </div>
    </>
  );
}
