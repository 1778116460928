import React from "react";
import second_11 from "../../img/ch/11.jpg";
import "./postpage.css";
import Footer from "../../footer/footer";
export default function Postnewspage() {
  return (
    <>
      <br />
      <br />
      <br />
      <br />

      <section id="gallery ">
        <div className="main-div-img">
          <div className="container ">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="">หน้าหลัก</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  ข่าวสาร
                </li>
              </ol>
            </nav>
            <div className="list-menu-homepage">
              <div className="padding-Title-Page">
                <h1 className="Title-Page">ข่าวสาร</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 mb-4">
                <div className="card">
                  <img src={second_11} alt="" className="card-img-top" />
                  <div className="card-body">
                    <h5 className="card-title">Sunset</h5>
                    <p className="card-text">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Ut eum similique repellat a laborum, rerum voluptates
                      ipsam eos quo tempore iusto dolore modi dolorum in
                      pariatur. Incidunt repellendus praesentium quae!
                    </p>
                    <a href="/Postdetailpage" className="btn btn-outline-success btn-sm">
                      Read More
                    </a>
                    <a href="" className="btn btn-outline-danger btn-sm">
                      <i className="far fa-heart"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="card">
                  <img
                    src="https://images.unsplash.com/photo-1516214104703-d870798883c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=60"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Sunset</h5>
                    <p className="card-text">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Ut eum similique repellat a laborum, rerum voluptates
                      ipsam eos quo tempore iusto dolore modi dolorum in
                      pariatur. Incidunt repellendus praesentium quae!
                    </p>
                    <a href="" className="btn btn-outline-success btn-sm">
                      Read More
                    </a>
                    <a href="" className="btn btn-outline-danger btn-sm">
                      <i className="far fa-heart"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="card">
                  <img
                    src="https://images.unsplash.com/photo-1477862096227-3a1bb3b08330?ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Sunset</h5>
                    <p className="card-text">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Ut eum similique repellat a laborum, rerum voluptates
                      ipsam eos quo tempore iusto dolore modi dolorum in
                      pariatur. Incidunt repellendus praesentium quae!
                    </p>
                    <a href="" className="btn btn-outline-success btn-sm">
                      Read More
                    </a>
                    <a href="" className="btn btn-outline-danger btn-sm">
                      <i className="far fa-heart"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
   
            <div className="d-flex justify-content-center align-items-center">
              <nav aria-label="Page navigation example ">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <Footer/>
        </div>
      </section>
    </>
  );
}
