import React from "react";
import Footer from "../../footer/footer";
import { useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import { WithContext as ReactTags } from "react-tag-input";
import "./reactTags.css";
import homeApi from "../../api/homeApi";
import { useParams } from "react-router-dom";
import Swal from "sweetalert";
import APINAME from "../../api/apiName";
import Editor from "../../editer/Editor"
import CurrentNAME from "../../api/currentName";
Quill.register("modules/imageResize", ImageResize);
 
export default function Frompostdetailpage() {
  //-----Quill
  window.Quill = Quill;

  let { postid } = useParams();
  const [Id, setId] = useState(0);

  const [tags, setTags] = React.useState([]);

  //----getdata
  const [formData, setFormData] = useState({
    post_id: "",
    post_date: "",
    main_image_id: "",
    post_titel: "",
    post_content: "",
    update_date: "",
    base64_content: "",
    main_post_id:"",
  });
  const [editorContent, setEditorContent] = useState("");
  useEffect(() => {
    setId(postid);
    if (postid !== "0" || postid !== "-1") {
      //Fetch user data based on the ID from the URL params
      homeApi.get(`news/` + postid)
        .then((response) => {
          const responseData = response.data[0];
          setFormData({
            post_id: responseData.post_id,
            post_date: responseData.post_date,
            main_image_id: responseData.main_image_id,
            post_titel: responseData.post_titel,
            post_content: responseData.post_content,
            update_date: responseData.update_date,
            base64_content: responseData.base64_content, 
            main_post_id: responseData.main_post_id, 
          });


          setEditorContent(responseData.post_content);
          setTitel(responseData.post_titel);

          setFileId(responseData.main_image_id)
          setFile(APINAME+responseData.post_image_name)
        })
        .catch((error) => {
          console.error("Error fetching user data", error);
        });

      // homeApi.get("news-imges-detail/" + postid)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     setSelectedFiles(data.map((file) => ({
      //       file_id: file.post_image_id,
      //       name: file.post_image_name,
      //       type: file.post_image_type,
      //       base64: file.base64_content,
      //     })));

      //   })
      //   .catch((error) => console.error("Error fetching data:", error));

      homeApi.get(`news-imges-detail/${postid}`)
      .then((response) => {
        const data = response.data;
    
        const mappedData = data.map((file) => ({
          file_id: file.post_image_id,
          name: file.post_image_name,
          type: file.post_image_type,
          base64: file.base64_content,
        }));
    
        setSelectedFiles(mappedData);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
    
      // try {
      //   const response =  homeApi.get(`news-imges-detail/${postid}`);
      //   const data = response.data;

      //   // Map data to the desired format
      //   const mappedData = data.map((file) => ({
      //     file_id: file.post_image_id,
      //     name: file.post_image_name,
      //     type: file.post_image_type,
      //     base64: file.base64_content,
      //   }));

      //   // Set the state with the mapped data
      //   setSelectedFiles(mappedData);
      // } catch (error) {
      //   console.error("Error fetching data:", error);
      // }

      if (formData.main_image_id !== "") {
        homeApi.get(`news-imges/${formData.main_image_id}`)
          .then((response) => {
            setFile(response.data[0].base64_content);
          })
          .catch((error) => {
            console.error("Error fetching user data", error);
          });
      }

      homeApi.get(`Tags/${postid}`)
        .then((response) => {
          setTags(response.data);
      
        })
        .catch((error) => {
          console.error("Error fetching user data", error);
        });
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postid]);

  //--DATA
  const editorStyle = {
    minHeight: "300px", // Set the minimum desired height
    height: "auto", // Set the height to 'auto' to allow it to expand as needed
    width: "100%", // Set the desired width (100% of the parent container)
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"], // Include 'image' in the toolbar options
      [{ size: ["small", false, "large", "huge"] }],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize", "Toolbar"],
      // See optional "config" below
    },
  };

  const [file, setFile] = useState("");
  const [fileType, setfileType] = useState();
  const [fileName, setfileName] = useState();
  const [fileId, setFileId] = useState("");
  const [filebase,setFileBase] = useState();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    // Assuming you want to display the selected file path
    const filePath = URL.createObjectURL(selectedFile);
    setFile(filePath);
  
    // Other file-related states
    setfileType(selectedFile.type);
    setfileName(selectedFile.name);
    setFileBase(selectedFile);

  };

  const [data, setData] = useState([]);

  const [titel, setTitel] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  //---FUCTION

  //-----inputtag
  const [deletedTags, setDeletedTags] = useState([]);
  const handleDelete = (i) => {
    const deletedTag = tags[i]; // Save the tag that is going to be deleted
    setTags(tags.filter((tag, index) => index !== i));
    
    // Store the deleted tag in another value or perform any necessary actions
    // For example, you can store it in a separate state or an array.
    // Example:
    const deletedTagsArray = [...deletedTags]; // Assuming deletedTags is another state or array
    deletedTagsArray.push(deletedTag);
    setDeletedTags(deletedTagsArray); // Set the state for the deleted tags4
  
  };
  

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
   
  };

  //----------Upload File

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShow(true);
  };

  const handleCloseModal = () => setShowModal(false);
  const [show, setShow] = useState(false);

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const renderData = () => {
  
    const rows = [];
    for (let i = 0; i < data.length; i += 3) {
      const chunk = data.slice(i, i + 3);
      const chunkMarkup = (
        <div className={`carousel-item ${i === 0 ? "active" : ""}`} key={i}>
          <div className="row">
            {chunk.map((item, idx) => (
              <div
                className="col-md-4 mb-3"
                key={idx}
                onClick={() => handleImageClick(item.base64_content)}
              >
                <div className="card">
                  <img
                    className="img-fluid"
                    alt="100%x280"
                    src={item.base64_content}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      );
      rows.push(chunkMarkup);
    }

    return (
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">{rows}</div>

        {show && (
          <div
            className="modal "
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-fullscreen text-center"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    onClick={closeModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="">
                    <img className="i" alt="100%x280" src={selectedImage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };


 
  //--------insert data

  const InsertData = async () => {


    try {
      let img_id = "";

      if (filebase) {
        const formData = new FormData();
        formData.append('file', filebase);
        formData.append('id', "21");
      
        try {
          const response = await homeApi.post('create-news-images', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
      
          const { status, message, insertId } = response.data;
      
          console.log(insertId);
          console.log(response.data);
      
          img_id = insertId;
        } catch (error) {
          console.error('Error uploading file:', error);
          // Handle error
        }
      } else {
        console.error('No file selected');
        // Handle case where no file is selected
      }
      
      let main_id = "";
      let main_post_id = "";
      
      if (Id === "0") {
        main_post_id = "1";
      } else {
        main_post_id = "2";
      }
      
      try {
        const response = await homeApi.post("create-news", {
          post_titel: formData.post_titel,
          post_content: formData.post_content,
          main_image_id: img_id,
          main_post_id: main_post_id,
        });
      
        main_id = response.data.insertId;
      } catch (error) {
        console.error('Error creating news:', error);
        // Handle error
      }
      

      await homeApi.post("addtags", {
        Id: main_id,
        tag: tags,
      })
        .then((response) => {
   
          setTags([...tags, tags]);
        })
        .catch((error) => {
          console.error("Error adding tag:", error);
        });

      fileData.forEach(async (file) => {
        await homeApi.post("create-news-imges", {
          post_image_name: file.name,
          post_image_type: file.type,
          base64_content: file.base64,
          post_id: main_id,
        }).then((response) => {
          setSelectedFiles([]);
        });
      });



      Swal({
        icon: "success",
        text: "บันทึกสำเร็จ",
      }).then((result) => {
        
             // Redirect with main_id as a query parameter
      window.location.href = CurrentNAME+`Frompostdetailpage/${main_id}`;

      
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const UpdateData = async () => {


    try {
      const response = await homeApi.post("update-news", {
        post_titel: formData.post_titel,
        post_content: formData.post_content,
        post_id: Id,
      });
      const updatedData = response.data; // Assuming your API returns the updated data
  

      if (filebase) {
        const formData = new FormData();
        formData.append('file', filebase);
        formData.append('id', fileId);

      
        try {
          const response = await homeApi.post('update-news-imges', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
      
          const { status, message, insertId } = response.data;
      
          console.log(insertId);
          console.log(response.data);

        } catch (error) {
          console.error('Error uploading file:', error);
          // Handle error
        }
      } else {
        console.error('No file selected');
        // Handle case where no file is selected
      }
      
      // await homeApi.post("update-news-imges", {
      //   post_image_name: fileName,
      //   post_image_type: fileType,
      //   base64_content: file,
      //   main_image_id: fileId, // You might need to change this value based on your requirements
      // }).then((response) => {
      //   const insertId = response.data.insertId;
      //   // handle the insertId if necessary
      // });
      // handle the insertId or perform any necessary actions
    
      // alert(deletedFiles.length);
      if ( deletedFiles.length > 0) {
        await homeApi.post("delete-news-imges", {
          deletedFiles: deletedFiles,
        }).then((response) => {
          // handle the insertId if necessary
        });
      }


      fileData.forEach(async (file) => {

        if(file.file_id === "0"){
          await homeApi.post("create-detail-image", {
            post_image_name: file.name,
            post_image_type: file.type,
            base64_content: file.base64,
            post_id: Id,
          })
          // .then((response) => {
          //   setSelectedFiles([]);
          // });
      
        }
      });

      if ( deletedTags.length > 0) {
        const parsedIdArray = [];
        deletedTags.forEach((tag) => {
          const parsedId = parseInt(tag.id);
          if (!isNaN(parsedId)) {
            // Perform actions if the parsedId is a valid integer
            parsedIdArray.push(parsedId);
            homeApi.get(`Tags-delete/${parsedId}`)
            .then((response) => {
           console.log("delete");
           
            })
            .catch((error) => {
              console.error("Error fetching user data", error);
            });
          }
        });
      }

      Swal({
        icon: "success",
        text: "อัพเดตสำเร็จ",
      }).then((result) => {
        
             // Redirect with main_id as a query parameter
      window.location.href = `${CurrentNAME}Frompostdetailpage/${Id}`;

      
      });
      
    } catch (error) {
      // handle the error appropriately
      console.error("Error in updating data:", error);
    }
  };
  
  const DeleteData = async () =>{

    homeApi.get(`delete-news/${Id}`)
      .then((response) => {
        if(response.data.affectedRows > 0){

          Swal({
            icon: "success",
            text: "ลบสำเร็จ",
          }).then((result) => {
            
                 // Redirect with main_id as a query parameter

          if(parseInt(formData.main_post_id) == 2){
            window.location.href = CurrentNAME+`Postpage/2`;
          }else{
            window.location.href = CurrentNAME+`Postpage/1`;
          }
          });

        }
 
      })
      .catch((error) => {
        console.error("Error adding tag:", error);
      });
  }
  

  //--------------

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileItem = {
          file_id:"0",
          name: file.name,
          type: file.type,
          base64: reader.result,
        };
        setFileData((prev) => [...prev, fileItem]);
      };
    });

    setSelectedFiles([...selectedFiles, ...files]);
  };



  const handleRemove = (index) => {
    const updatedFiles = [...selectedFiles];
    const deletedFile = updatedFiles.splice(index, 1)[0]; // Remove the file from selectedFiles and store the deleted file
    setSelectedFiles(updatedFiles);

    const updatedFileData = [...fileData];
    updatedFileData.splice(index, 1);
    setFileData(updatedFileData);

    setDeletedFiles((prev) => [...prev, deletedFile.file_id]);
  };





  const handleEditorContentChange = (newContent) => {
    setEditorContent(newContent);
    setFormData({ ...formData, post_content: newContent })
    // Do any other necessary actions with the updated content
  };
  return (
    <>
      <br />
      <br />
      <br />
      <br />

      <div className="main-div-img">
        <div className="container ">
          <div className="row">
            <div className="col-8">
              {" "}
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">หน้าหลัก</a>
                  </li>
                  <li className="breadcrumb-item " aria-current="page">
                    <a href="/">โพส</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    บันทึกโพส
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-4  text-right">
              {(Id === "0" || Id === "-1")&& (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={InsertData}
                  >
                    บันทึก
                  </button>
                  {/* <a href=""  type="button" className="btn btn-secondary">
                  บันทึกแบบร่าง
                  </a> */}
                </div>
              )}

              {(Id !== "0" && Id !== "-1") && (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={UpdateData}
                  >
                    อัพเดต
                  </button>
                  <a href={`/Postdetailpage/`+formData.post_id} type="button" className="btn btn-secondary">
                    ดูโพส
                  </a>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={DeleteData}
                  >
                    ลบโพส
                     </button>
                </div>
              )}
            </div>
          </div>

          <div className="col-lg">
            <article>
              <header className="mb-4">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="post_titel"
                  placeholder="ชื่อเรื่อง"
                  value={formData.post_titel }  
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      post_titel: e.target.value,
                    }))
                  }
                />

                <div className="text-muted fst-italic mb-2">
                  Posted on {formData.post_date}
                </div>
                <div></div>
                {/* <div>
                  <ReactTags
                    tags={tags}
                    // suggestions={suggestions}
                    // delimiters={delimiters}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    handleTagClick={handleTagClick}
                    inputFieldPosition="inline"
                    autocomplete
                  />
                </div> */}
              </header>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                id="formFileMultiple"
                //  onChange={getFile}
                onChange={handleFileChange}
                //convertToBase64
                multiple
              />
              {file && (
                <figure className="mb-4 d-flex justify-content-center align-items-center">
                               <img
  className="img-fluid rounded"
  src={file}
  alt="..."
  style={{
    width: "100%",  // Ensure the image takes 100% of its parent's width
    height: "auto", // Allow the height to adjust proportionally
    maxWidth: "900px", // Set a maximum width if needed
  }}
/>
                </figure>
              )}

              <section className="mb-5 ">
                {/* <div className="preview-container">
              
                  <div
                    dangerouslySetInnerHTML={{ __html: formData.post_content }}
                  />

                  <section class="pt-5 pb-5">
                    <div class="container">
                      <div class="row">
                        <div class="col-6">
                    
                        </div>
                        <div class="col-6 text-right">
                          <a
                            class="btn btn-primary mb-3 mr-1"
                            href="#carouselExampleIndicators2"
                            role="button"
                            data-slide="prev"
                          >
                            <i class="fa fa-arrow-left"></i>
                          </a>
                          <a
                            class="btn btn-primary mb-3 "
                            href="#carouselExampleIndicators2"
                            role="button"
                            data-slide="next"
                          >
                            <i class="fa fa-arrow-right"></i>
                          </a>
                        </div>
                        <div class="col-12">
                          <div class="carousel-inner">{renderData()}</div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div> */}
              </section>
            </article>
          </div>
          <div className="bg-white">
             <Editor content={editorContent} onContentChange={handleEditorContentChange} />
             {(Id !== "0" && Id !== "-1") && (
            <input type="file" multiple onChange={handleFileInputChange} />)}
            {(Id !== "0" && Id !== "-1") && (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>File Size</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedFiles.map((file, index) => (
                  <tr key={index}>
                    <td>{file.name}</td>
                    <td>{file.size} bytes</td>
                    <td>
                      <button onClick={() => handleRemove(index)}>
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            )}
            {/* <button onClick={handleUpload}>Upload</button> */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
