import loginstyle from "./Login.css";
import logo from "../../src/img/logo2.png";
import { useState } from "react";
import { Container } from "react-bootstrap";
export default function Login() {
  const [UserName, SetuserName] = useState();
  const [Password, SetPassword] = useState();
  // const [Role, SetRole] = useState();

  const Login = (event, value) => {
    sessionStorage.setItem("UserName", UserName);
    sessionStorage.setItem("Password", Password);
    sessionStorage.setItem("Role", "admin");

    window.location.href = `/`;
  };

  return (
    <>
      <Container>
        <br />
        <br />
        <div class="card text-center">
          <div class="card-header">Featured</div>
          <div class="card-body">
            
              <div class="fadeIn first">
                <img src={logo} id="icon" alt="User Icon" />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  id="login"
                  class="form-control"
                  name="login"
                  placeholder="login"
                  onChange={(event) => {
                    SetuserName(event.target.value);
                  }}
                  value={UserName}
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  id="password"
                  class="form-control"
                  name="login"
                  placeholder="password"
                  onChange={(event) => {
                    SetPassword(event.target.value);
                  }}
                  value={Password}
                />
              </div>
              <input
                type="submit"
                class="fadeIn fourth"
                value="Log In"
                onClick={Login}
              />
           
          </div>
        </div>
      </Container>
    </>
  );
}
