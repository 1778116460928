import React from "react";
import second_11 from "../../img/ch/11.jpg";
import "./postpage.css";
import Footer from "../../footer/footer";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { grey } from "@mui/material/colors";
import homeApi from "../../api/homeApi";
import APINAME from "../../api/apiName";
export default function Postpage() {
  let { id } = useParams();
  const [data, setData] = useState([]);
  const [titel, setTitel] = useState([]);
  const [UserRole, setUserRole] = useState();

   const storeRole =  sessionStorage.getItem("Role");

  useEffect(() => {

    setUserRole(storeRole);
    // Fetch data from API
    // Example API call
      homeApi.get(`news-post/${id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    switch (id) {
      case "1": // Changed from 'id === 1' to '1'
        setTitel("ผลงาน");
        break;
      case "2": // Changed from 'id === "2"' to '2'
        setTitel("ข่าวสาร");
        break;
    }
  }, []);

  const renderData = () => {
    if (data.length === 0) {
      return <div class="d-flex justify-content-center">

      <h1>ไม่พบข้อมูล</h1>
    
    </div>
    
    
    ; // Or any other loading indicator
    }

    const rows = [];
    for (let i = 0; i < data.length; i += 3) {
      rows.push(
        <div className="row" key={i}>
          {data.slice(i, i + 3).map((item, index) => {
            const postDate = new Date(item.post_date);

            return (
              <div className="col-lg-4 mb-4" key={index}>
                <div className="card">
                  <img
                    src={APINAME+item.post_image_name}
                    alt=""
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <p className="card-text datecard">
                      {postDate.toLocaleString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        timeZone: "Asia/Bangkok",
                      })}
                    </p>
                    <h5 className="card-title">{item.post_titel}</h5>
                    <div className="contentcard">
                      <div
                        style={{
                          maxHeight: "100px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal", // or 'nowrap' if you don't want text wrapping
                          textEmphasisColor: grey,
                        }}
                        dangerouslySetInnerHTML={{ __html: item.post_content }}
                      />
                     
                    </div>
                    <a
                      href={`/Postdetailpage/${item.post_id}`}
                      className="btn btn-outline-success btn-sm"
                    >
                     อ่านเพิ่มเติม
                    </a>
                    {
                      (UserRole === "admin")&&(
                        <a
                        href={`/Frompostdetailpage/${item.post_id}`}
                        className="btn btn-outline-danger btn-sm"
                      >
                        แก้ไข
                      </a>
                      )
                    }
                  
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return rows;
  };

  return (
    <>
    
      <br />
      <br />
      <br />
      <br />

      <section id="gallery ">
        <div className="main-div-img">
          <div className="container ">
            <div className="row">
              <div className="col-8">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a className="card-link" href="/">หน้าหลัก</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {titel}
                    </li>
                  </ol>
                </nav>
              </div>
              {
                      (UserRole === "admin")&&(
              <div className="col-4  text-right">
                {id === "1" && (
                  <a
                    type="button"
                    className="btn btn-primary addbutton"
                    href="/Frompostdetailpage/0"
                  >
                    เพิ่มโพส
                  </a>
                )}
                {id === "2" && (
                  <a
                    type="button"
                    className="btn btn-primary addbutton"
                    href="/Frompostdetailpage/-1"
                  >
                    เพิ่มโพส
                  </a>
                )}
              </div>
                      )}
            </div>
            <div className="list-menu-homepage">
              <div className="padding-Title-Page">
                <h1 className="Title-Page">{titel}</h1>
              </div>
            </div>
            {renderData()}

            {/* <div className="d-flex justify-content-center align-items-center">
              <nav aria-label="Page navigation example ">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div> */}
            <br/>
          </div>
          <Footer />
        </div>
      </section>
    </>
  );
}
