import React, { useState } from 'react';
import axios from 'axios';
import homeApi from '../api/homeApi';
import CurrentNAME from '../api/currentName';

const UploadFile = () => {
   const [selectedFile, setSelectedFile] = useState(null);

   const handleFileUpload = (event) => {
     setSelectedFile(event.target.files[0]);
   };

   const handleUpload = () => {
     const formData = new FormData();
     formData.append('file', selectedFile);
     homeApi.post('create-banner-image', formData)
       .then((response) => {
         console.log(response.data);
       })
       .catch((error) => {
         console.log(error);
       });

       window.location.href = CurrentNAME;
   };

   return(
     <div>
       <h3>ภาพแบนเนอร์</h3>
       <input class="form-control" type="file" onChange={handleFileUpload} />
       <button  class="btn btn-success" onClick={handleUpload}>Upload</button>
     </div>
   );
};

export default UploadFile;