import Homepage from "./page/homepage/homepage";
import TopBar2 from "./topbar/TopBar2";
import TopBar3 from "./topbar/TopBar3";
import TopBar1 from "./topbar/TopBar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Router, Routes, Route,Link } from 'react-router-dom';
import Postpage from "./page/postpage/postpage";
import Postdetailpage from "./page/postdetailpage/postdetailpage";
import Postnewspage from "./page/postpage/postnewspage";
import Frompostdetailpage from "./page/postdetailpage/frompostdetailpage";
import Homepin from "./page/hometype/homepin";
import Homepindetail from "./page/homepindetail/homepindetail";

import Login from "./loginpage/Login";
import Contractpage from "./page/contractpage/contractpage";
import { Helmet } from "react-helmet";
import logo from "./img/logo2.png"
import FileUploadComponent from "./page/homePage2/homepage2";


function App() {
  return (
  
   
    <BrowserRouter basename="/">

<div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>ฮักสร้างบ้าน</title>
                <link rel="canonical" href="http://mysite.com/example" />
                <link rel="icon" href={logo} type="image/x-icon"></link>
            </Helmet>
        </div>
        <TopBar3/>
      <Routes>
      <Route path="/"  element={<Homepage />} />

      <Route path="/home"  element={<Homepage />} />
          <Route path="/Postpage/:id" element={<Postpage />} />
            <Route path="/Postnewspage/:id" element={<Postnewspage />} />
            <Route path="/Frompostdetailpage/:postid" element={<Frompostdetailpage />} />
            <Route path="/Postdetailpage/:id" element={  <Postdetailpage/>} />
            <Route path="/Homepin" element={  <Homepin/>} />
           <Route path="/Homepin" element={  <Homepin/>} />
            <Route path="/Homepin/:id" element={  <Homepin/>} />
            <Route path="/Homepindetail/:id" element={  <Homepindetail/>} />
            <Route path="/admin" element={<Login/>} />
            <Route path="/contractpage" element={<Contractpage/>} />
      </Routes>
    </BrowserRouter>
    
  );
}



export default App;