import React from "react";
import Footer from "../../footer/footer";
import { useParams } from "react-router-dom";
import homeApi from "../../api/homeApi";
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import second_1 from "../../img/ch/10.jpg";
import "./homepindetail.css";
import Sharebutton from "../../sharebutton/Sharebutton";
import APINAME from "../../api/apiName";
import { Helmet } from "react-helmet";
export default function Homepindetail() {
  let { id } = useParams();

  // const [data, setData] = useState([]);

  const [data, setData] = useState('');
  

  //--------------
  const [file, setFile] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [fileType, setfileType] = useState();
  const [fileName, setfileName] = useState();
  const [fileId, setFileId] = useState([]);

  const [homepinid, setHomepinid] = useState("");
  const [homepinname, setHomepinname] = useState([]);
  const [nliviingromm, setnliviingromm] = useState([]);
  const [nkitchenroom, setNkitchenroom] = useState([]);
  const [nbedroom, setnbedroom] = useState([]);
  const [nbathroom, setbathroom] = useState([]);
  const [nparking, setparking] = useState([]);
  const [housefloor, sethousefloor] = useState([]);
  const [mainstyleid, setMainstyleid] = useState(1);
  const [mainstylename, setMainstylename] = useState();
  const [priceragestart, setPriceragestart] = useState([]);
  const [ndining, setNdining] = useState([]);

  const rendercarouselData = () => {

    if (fileData.length === 0) {
      return <p>Loading...</p>; // Or any other loading indicator
    }
    return (
      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          {fileData.map((item, index) => (
            <div key={index} className={index === 0 ? 'carousel-item active' : 'carousel-item'}>
              <img className="imgcarousel pinimgcarousel" src={APINAME+item.pin_image_name} alt="" />
            </div>
          ))}
        </div>
        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  };
  const [selectedValuehometype, setSelectedValuehometype] = useState("");
  const [selectedValuefloor, setSelectedValuehomefloor] = useState("");
  const [selectedValuebedroom, setSelectedValuehomebedroom] = useState("");
  const [selectedValuebathroom, setSelectedValuehomebathroom] = useState("");
  const [selectedValueprice, setSelectedValueprice] = useState("");
  
  const handleAutocompleteChangehometype = (event, value) => {
    setSelectedValuehometype(value);
  };
  
  const handleAutocompleteChangefloor = (event, value) => {
    setSelectedValuehomefloor(value);
  };
  
  const handleAutocompleteChangebedroom = (event, value) => {
    setSelectedValuehomebedroom(value);
  };
  
  const handleAutocompleteChangebathroom = (event, value) => {
    setSelectedValuehomebathroom(value);
  };
  
  const handleAutocompleteChangeprice = (event, value) => {
    setSelectedValueprice(value);
  };

  const jsonData = {
    selectedValuehometype,
    selectedValuefloor,
    selectedValuebedroom,
    selectedValuebathroom,
    selectedValueprice,
  };

  useEffect(() => {

 setData(window.location.href);


    //Fetch user data based on the ID from the URL params
    homeApi.get(`get-pin-house/` + id)
      .then((response) => {

        setHomepinid(response.data.homepinid);
        setHomepinname(response.data.homepinname);
        setFile(response.data.file);
        setSelectedFiles(response.data.fileData);
        setFileData(response.data.fileData);
        setfileType(response.data.fileType);
        setfileName(response.data.fileName);
        // setFileId(response.data.homepinname);
        setnliviingromm(response.data.nliviingromm);
        setNkitchenroom(response.data.nkitchenroom);
        setnbedroom(response.data.nbedroom);
        setbathroom(response.data.nbathroom);
        setparking(response.data.nparking);
        sethousefloor(response.data.housefloor);
        setMainstyleid(response.data.mainstyleid);
        setMainstylename(response.data.mainstylename);
        setPriceragestart(response.data.priceragestart);
        setNdining(response.data.ndining);
        // selectedFiles.forEach((file, index) => {
        //   // alert(`File Name: ${file.base64_content}`);
      
        //   const fileItem = {
        //     file_id: file.pin_image_id ,
        //     name: file.pin_image_name,
        //     type: file.pin_image_type,
        //     base64: file.base64_content,
        //   };
        //   setFileData((prev) => [...prev, fileItem]);
       
        // });

      })
      .catch((error) => {
        console.error("Error fetching user data", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const top100Films = [
    { label: "ชยางกูร", year: "ชยางกูร" },
    { label: "ทรัพย์อนัต", year: "ทรัพย์อนัต" },
  ];

  const level = [
    { label: "1 ชั้น", year: "1 ชั้น" },
    { label: "2 ชั้น", year: "2 ชั้น" },
    { label: "3 ชั้น", year: "3 ชั้น" },
    { label: "4 ชั้น", year: "4 ชั้น" },
  ];

  return (
    <>


      <br />
      <br />
      <br />
      <br />

      <div className="main-div-img">
        <div className="container ">
          
          <div className="col-lg">
          <div className="container seachbar ">
          <div className="row">
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      color="white"
                      id="combo-box-demo"
                      options={hometype}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangehometype}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="แบบบ้าน"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={level}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangefloor}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ชั้น"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={bedroom}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangebedroom}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ห้องนอน"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={bathroom}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangebathroom}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ห้องน้ำ"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={price}
                      sx={{
                        width: "100%", // Set the initial width to 100% for responsiveness
                        "@media only screen and (min-width: 600px)": {
                          width: 160, // Set a specific width for larger screens
                        },
                      }}
                      onChange={handleAutocompleteChangeprice}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ราคา"
                          inputProps={{
                            ...params.inputProps,
                            style: { height: "20px", textAlign: "center" }, // Adjust the height as needed
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-2 col-sm-3 d-flex justify-content-right align-items-center">
                    <div className="col">
                      <button
                        type="button"
                        class="btn  btn-block custom-button"
                        
                      >
                        {" "}
                        <Link   className="seach-link" to="/homepin" state={jsonData}>
              ค้นหา
              </Link>
                      </button>
                    </div>
                  
                  </div>
                </div>

          </div>
            <div className="list-menu-homepage">
              <div className="padding-Title-Page">
                <h1 className="Title-Page">{mainstylename}</h1>
              </div>
            </div>
<div className="row">
  <div className="col-sm-8">
  <h1 className="fw-bolder mb-1">{homepinname}</h1>
            <h5>
              <i class="fa-solid fa-bed"></i> {nbedroom} ห้องนอน{" "}
              <i class="fa-solid fa-toilet"></i> {nbathroom} ห้องน้ำ{" "}
              <i class="fa-solid fa-car"></i> {nliviingromm} ห้องรับแขก
            </h5>
  </div>
  <div className="col-sm-4 d-flex justify-content-end">
  <Sharebutton currentUrl={data}/>
  </div>
</div>
        
          
            <div className="text-center">
              <div class="page-content page-container" id="page-content">
                <div class="padding">
                  <div class="row container d-flex justify-content-center">
                    <div class="col-sm-12">
                      <div class="mb-12">
                        <div class="tab-content mb-4">
                          <div
                            class="tab-pane fade show active"
                            id="home2"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                        
                         <div className="text-center">
                              <img className="main-img" src={APINAME+fileName} alt="" />
                            </div>
                                                      </div>
                          <div
                            class="tab-pane fade"
                            id="profile2"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                            
                            {rendercarouselData()}

                          </div>
                        </div>
                        <ul
                          class="nav nav-pills justify-content-center nav-fill"
                          id="myTab"
                          role="tablist"
                        >
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              id="home-tab"
                              data-toggle="tab"
                              href="#home2"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                            >
                              ภาพ
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="profile-tab"
                              data-toggle="tab"
                              href="#profile2"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                            >
                              รายละเอียดเพิ่มเติม
                            </a>
                          </li>
                        </ul>
                        <br />

                        <h3 className="Title-head">รายละเอียด</h3>

                        <table class="table">
                          <tbody>
                            <tr>
                              <th className="right-detail">ราคา</th>
                              <td className="left-detail">{(priceragestart).toLocaleString()} บาท</td>
                            </tr>
                            <tr>
                              <th className="right-detail">ห้องนอน</th>
                              <td className="left-detail"> {nbedroom} </td>
                            </tr>
                            <tr>
                              <th className="right-detail">ห้องน้ำ</th>
                              <td className="left-detail">{nbathroom} </td>
                            </tr>
                            <tr>
                              <th className="right-detail">ลานจอดรถ</th>
                              <td className="left-detail">{nparking}</td>
                            </tr>
                            <tr>
                              <th className="right-detail">ห้องครัว</th>
                              <td className="left-detail">{nkitchenroom} </td>
                            </tr>
                            <tr>
                              <th className="right-detail">จำนวนชั้น</th>
                              <td className="left-detail">{housefloor} </td>
                            </tr>
                            <tr>
                              <th className="right-detail">ห้องรับแขก</th>
                              <td className="left-detail">{nliviingromm} </td>
                            </tr>
                            <tr>
                              <th className="right-detail">ห้องทานอาหาร</th>
                              <td className="left-detail">{ndining} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      


       
        <Footer  />

      </div>
    </>
  );
}




const hometype = [
  { hometype_id: "1", label: "ชยางกูร" },
  { hometype_id: "2", label: "ทรัพย์อนัต์" },
  { hometype_id: "3", label: "อื่นๆ" },
];

const level = [
  { level_id: "1", label: "1" },
  { level_id: "2", label: "2" },
  { level_id: "3", label: "3" },
  { level_id: "4", label: "4" },
];

const bedroom = [
  { level_id: "1", label: "1" },
  { level_id: "2", label: "2" },
  { level_id: "3", label: "3" },
  { level_id: "4", label: "4" },
  { level_id: "5", label: "5" },
];

const bathroom = [
  { level_id: "1", label: "1" },
  { level_id: "2", label: "2" },
  { level_id: "3", label: "3" },
  { level_id: "4", label: "4" },
  { level_id: "5", label: "5" },
];

const price = [
  { pricestart: "500000",priceend: "1000000", label: "500,000 - 1,000,000" },
  { pricestart: "1000000",priceend: "1500000", label: "1,000,000 - 1,500,000" },
  { pricestart: "1500000",priceend: "2000000", label: "1,500,000 - ,2,000,000" },
  { pricestart: "2000000",priceend: "2500000", label: "2,000,000 - ,2,500,000" }
];